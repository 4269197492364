import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import CardInformation from "../../components/card/CardInformation";
import "./confirmYourData.css";
import CardConfirmYourData from "../../components/card/CardConfirmYourData/CardConfirmYourData";
import DataHolder from "../../components/card/CardConfirmYourData/DataHolder/DataHolder";
import DataContact from "../../components/card/CardConfirmYourData/DataContact/DataContact";
import InstallationData from "../../components/card/CardConfirmYourData/InstallationData/InstallationData";
import { RedirectContext } from "../../context/redirect";
import { useMutation } from "@tanstack/react-query";
import {
  DadosTitularPjProps,
  Distribuidora,
  confirmarDadosLead,
  termo,
} from "../../services/onboarding";
import {
  POST_CONFIRMAR_DADOS_LEAD,
  POST_PREPARE_TERM,
} from "../../query/constants";
import CnpjHolder from "../../components/card/CardConfirmYourData/CnpjHolder/CnpjHolder";
import { SessionContext } from "../../context/session";
import { setItemLocalStorage } from "../../utils/localStorage";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import { AxiosError } from "axios";
import { economiaAnual } from "../../utils/calculoFinal";
import { valorFornecimento } from "../../utils/valorFornecimento";
import { getImage } from "../../services/images";
import { textsPath } from "../../services/texts";

type DatasHolderProps = {
  nome: string;
  nacionalidade: string;
  cpf: string;
  rg?: string;
  rne?: string;
  maritalStatus: string;
};

export const ConfirmYourData = () => {
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [dadosDistribuidora, setDadosDistribuidora] = useState<Distribuidora| null>(null)
  const { setPathEdit, pathEdit } = useContext(RedirectContext);
  const [holderCnpj, setHolderCnpj] = useState(false);
  const [cnpjLegalRepresentative, setCnpjLegalRepresentative] =
    useState<DadosTitularPjProps>({
      CNPJ: "",
      RazaoSocial: "",
    });
  const [otherData, setOtherData] = useState({
    discount: 0,
    consumoMensal: "",
    economiaAnual: "",
    expectedEconomy: 0,
    numberInstallation: 0,
    address: "",
  });
  const [datasContact, setDatasContact] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [datasHolder, setDatasHolder] = useState<DatasHolderProps>({
    nome: "",
    nacionalidade: "",
    cpf: "",
    rg: "",
    rne: "",
    maritalStatus: "",
  });
  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;

  const prepareTerm = useMutation({
    mutationFn: termo,
    mutationKey: [POST_PREPARE_TERM],
    onSuccess: (data) => {
      setItemSession("term", data);
      navigate("/contract-of-adhesion");
    },
    onError(error: AxiosError) {
      console.log(error.response?.status);
    },
  });

  const confirmarDadosLeadMutation = useMutation({
    mutationFn: confirmarDadosLead,
    mutationKey: [POST_CONFIRMAR_DADOS_LEAD],
    onSuccess: () => {
      prepareTerm.mutate();
    },
  });

  useEffect(() => {
    const arquivo: string | null = getItemSession("dados-fatura");
    const contact: string | null = getItemSession("contact");
    const holderCPFDataStorage: string | null = getItemSession("holderData");
    const holderCnpjStorage: string | null = getItemSession("holderCNPJ");
    const cnpj: string | null = getItemSession("cnpj");
    const dadosDistribuidora = getItemSession(
      "dados-distribuidora"
    ) ? JSON.parse(
      getItemSession(
        "dados-distribuidora"
      )!
    ) : null
    setDadosDistribuidora(dadosDistribuidora);


    if (arquivo != null && dadosDistribuidora != null) {
      const desconto = dadosDistribuidora.descontoPadrao;
      const tarifa = dadosDistribuidora.tarifaVigente;
      let arquivopdf = JSON.parse(arquivo);
      const consumoMediao = arquivopdf.consumoMedio;
      const fornecimento = arquivopdf.fornecimento;

      const fornecimentoValue = valorFornecimento(fornecimento);

      const resposta = economiaAnual(
        fornecimentoValue,
        consumoMediao,
        tarifa,
        desconto
      );
      let opcoesEconomia = {
        style: "decimal" as any,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      let opcoesConsumo = {
        style: "decimal" as any,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };

      setOtherData({
        ...otherData,
        discount: desconto * 100,
        consumoMensal: arquivopdf.consumoMedio?.toLocaleString("pt-BR", opcoesConsumo),
        economiaAnual: resposta?.toLocaleString("pt-BR", opcoesEconomia),
        numberInstallation: arquivopdf.numeroInstalacao,
        address: arquivopdf.endereco,
      });
    }

    if (cnpj != null) {
      const cnpjObj = JSON.parse(cnpj);
      // console.log("cnpj e razao:", cnpjObj);
      setHolderCnpj(true);
      setCnpjLegalRepresentative(cnpjObj);
    }
    if (cnpj != null && holderCnpjStorage != null) {
      let inforHolderCNPJ = JSON.parse(holderCnpjStorage);
      // console.log("holderCNPJ", inforHolderCNPJ);

      // setHolderCnpj(false);

      setDatasHolder({
        ...datasHolder,
        nome: inforHolderCNPJ.nome,
        nacionalidade: inforHolderCNPJ.selectedNacionalidades,
        cpf: inforHolderCNPJ.cpf,
        [inforHolderCNPJ.rg ? "rg" : "rne"]: inforHolderCNPJ.rg
          ? inforHolderCNPJ.rg
          : inforHolderCNPJ.rne,
        maritalStatus: inforHolderCNPJ.selectedMaritalStatus,
      });
    }
    if (contact != null) {
      let infoContact = JSON.parse(contact);
      setDatasContact({
        ...datasContact,
        name: infoContact.name,
        email: infoContact.email,
        phone: infoContact.phone,
      });
    }

    if (holderCPFDataStorage != null) {
      let inforHolderCPF = JSON.parse(holderCPFDataStorage);
      setDatasHolder({
        ...datasHolder,
        nome: inforHolderCPF.nome,
        nacionalidade: inforHolderCPF.selectedNacionalidades,
        cpf: inforHolderCPF.storageCpf,
        [inforHolderCPF.rg ? "rg" : "rne"]: inforHolderCPF.rg
          ? inforHolderCPF.rg
          : inforHolderCPF.rne,
        maritalStatus: inforHolderCPF.selectedMaritalStatus,
      });
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);
  const handleBackButtonClick = () => {
    // Lógica a ser executada ao clicar no botão
    navigate("/installation");
  };

  const handleButtonClick = () => {
    //const faturaStorage = getItemSession("formData");
    if (pathEdit) {
      setPathEdit("");
    }
    const contato = {
      Nome: datasContact.name,
      Telefone: datasContact.phone,
    };
    const documentoOficial = datasHolder.rg ? datasHolder.rg : datasHolder.rne;
    if (documentoOficial) {
      const dadosUpdate = {
        CPF: datasHolder.cpf,
        EstadoCivil: datasHolder.maritalStatus,
        Nacionalidade: datasHolder.nacionalidade,
        Nome: datasHolder.nome,
        RG: documentoOficial,
      };
      confirmarDadosLeadMutation.mutate({
        Contato: { ...contato },
        dadosRepresentante: { ...dadosUpdate },
        isCnpj: holderCnpj,
      });
    }
  };

  // const cardTitle = "Você economizará";
  const cardDiscount = `${otherData.discount}`;
  const cardOtherData = {
    consumoMensal: `${otherData.consumoMensal} kWh`,
    economiaAnual: `R$ ${otherData.economiaAnual}`,
    expectedEconomy: `R$ ${otherData.expectedEconomy}`,
  };

  const installationDataTitle = "Dados do imóvel";
  const installationData = {
    distributor: `${dadosDistribuidora?.nome}`,
    numberInstallation: `${otherData.numberInstallation}`,
    address: `${otherData.address}`,
  };

  const holderDataTitle = "Dados do Titular";
  const representanteLegal = "Dados do Representante Legal";

  const holderData = {
    name: `${datasHolder.nome}`,
    nationalite: `${datasHolder.nacionalidade}`,
    cpf: `${datasHolder.cpf}`,
    rgRneLabel: datasHolder.rg ? "RG" : "RNE",
    rgRneValue: `${datasHolder.rg ? datasHolder.rg : datasHolder.rne}`,
    maritalStatus: `${datasHolder.maritalStatus}`,
  };

  const dadosTitularCNPJ = {
    cnpj: `${cnpjLegalRepresentative.CNPJ}`,
    razao: `${cnpjLegalRepresentative.RazaoSocial}`,
  };

  const contactDataTitle = "Dados de Contato";
  const contactData = {
    name: `${datasContact.name}`,
    email: `${datasContact.email}`,
    phone: `${datasContact.phone}`,
  };

  const handleEditInstallationData = () => {
    sessionStorage.setItem("path", "/confirm-your-data");
    navigate("/");
  };

  const handleEditHolderData = () => {
    if (holderCnpj) {
      setItemLocalStorage("path", "/confirm-your-data");
      navigate("/holder");
    }
    setItemLocalStorage("path", "/confirm-your-data");
    navigate("/create-holder");
  };

  const handleEditContactData = () => {
    setItemLocalStorage("path", "/confirm-your-data");
    navigate("/contact");
  };

  const handleEditHolderDataCNPJ = () => {
    setItemLocalStorage("path", "/confirm-your-data");
    navigate("/create-holder-cnpj");
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("confirmYourData")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="card-comfirmData"
        title={title}
        description={description}
      >
        <div className="card-container-confirm">
          <CardInformation
            discount={cardDiscount}
            consumoMensal={cardOtherData.consumoMensal}
            economiaAnual={cardOtherData.economiaAnual}
          />

          {/* Dados da Instalação: */}
          <CardConfirmYourData
            title={installationDataTitle}
            onEditClick={handleEditInstallationData}
          >
            <InstallationData
              distributor={installationData.distributor}
              numberInstallation={installationData.numberInstallation}
              address={installationData.address}
            />
            {/* <InstallationData data={installationData} /> */}
          </CardConfirmYourData>

          {/* Dados do TitularCNPJ/CPF: */}
          {holderCnpj ? (
            <CardConfirmYourData
              title={holderDataTitle}
            >
              <CnpjHolder
                cnpj={dadosTitularCNPJ.cnpj}
                social={dadosTitularCNPJ.razao}
              />
              {/* <DataHolder data={dadosTitularCNPJ} /> */}
            </CardConfirmYourData>
          ) : (
            <CardConfirmYourData
              title={holderDataTitle}
              onEditClick={handleEditHolderData}
            >
              <DataHolder
                name={holderData.name}
                nationalite={holderData.nationalite}
                cpf={holderData.cpf}
                rgRneLabel={holderData.rgRneLabel}
                rgRneValue={holderData.rgRneValue}
                maritalStatus={holderData.maritalStatus}
              />
            </CardConfirmYourData>
          )}

          {/* Dados do Representante Legal: */}

          {holderCnpj && (
            <CardConfirmYourData
              title={representanteLegal}
              onEditClick={handleEditHolderDataCNPJ}
            >
              <DataHolder
                name={holderData.name}
                nationalite={holderData.nationalite}
                cpf={holderData.cpf}
                rgRneLabel={holderData.rgRneLabel}
                rgRneValue={holderData.rgRneValue}
                maritalStatus={holderData.maritalStatus}
              />
              {/* <DataHolder data={holderData} /> */}
            </CardConfirmYourData>
          )}

          {/* Dados de contato: */}
          <CardConfirmYourData
            title={contactDataTitle}
            onEditClick={handleEditContactData}
          >
            <DataContact
              name={contactData.name}
              email={contactData.email}
              phone={contactData.phone}
            />
            {/* <DataContact data={contactData} /> */}
          </CardConfirmYourData>
        </div>
        <div className="button-container-confirm-data ">
          <ButtonComponent
            className="back-button"
            onClick={handleBackButtonClick}
          >
            Voltar
          </ButtonComponent>

          <ButtonComponent
            className="continue-button"
            onClick={handleButtonClick}
            isDisabled={confirmarDadosLeadMutation.isPending ? true : false}
          >
            Continuar
          </ButtonComponent>
        </div>

        {(confirmarDadosLeadMutation.isPending || prepareTerm.isPending) && (
          <LoadingFullPage />
        )}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
